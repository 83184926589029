import store from '@/store'
import { mdiAccountCheckOutline, mdiAccountOutline, mdiAccountPlusOutline, mdiAccountRemoveOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useUsersList() {
  const userListTable = ref([])

  const tableColumns = [
    {
      text: 'ID',
      value: 'uid',
      sortable: false,
      width: '50px',
    },
    {
      text: 'Durum',
      value: 'aktif',
      width: '90px',
    },
    {
      text: 'Cari Kodu',
      value: 'cari_kod',
      width: '150px',
    },
    {
      text: 'Ad Soyad',
      value: 'adsoyad',
      width: '200px',
    },
    {
      text: 'Müşteri Adı',
      value: 'customer_name',
      width: '300px',
    },
    {
      text: 'Kullanıcı Adı',
      value: 'username',
      width: '200px',
    },

    {
      text: 'Son Giriş',
      value: 'son_giris',
      width: '200px',
    },
    {
      text: 'Kayıt Tarihi',
      value: 'kayit_tarihi',
      width: '200px',
    },
    {
      text: 'Max.Taksit Bilgisi',
      value: 'max_installment',
      width: '80px',
      align: 'center', // Burada ortalamak için ekledik
    },
    {
      text: 'Pos Özel Kuralları',
      value: 'pos_rules',
      width: '200px',
    },
    {
      text: '',
      value: 'actions',
      align: 'right min-width-150',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const customerGroupFilter = ref(null)
  const cariKodFilter = ref(null)
  const idFilter = ref(null)
  const statusFilter = ref(null)
  const nameFilter = ref(null)
  const usernameFilter = ref(null)
  const emailFilter = ref(null)
  const customerFilter = ref(null)
  const updatedFilter = ref(null)

  const totalUserListTable = ref(0)
  const customerGroupOptions = ref([])
  const roleOptions = ref([])
  const pricelistOptions = ref([])

  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const userTotalLocal = ref([])
  const selectedRows = ref([])

  const statusOptions = [
    { title: 'Pasif', value: '0' },
    { title: 'Aktif', value: '1' },
  ]

  // fetch data
  const fetchUsers = () => {
    store
      .dispatch('app-user/fetchUsers', {
        q: searchQuery.value,
        options: options.value,
        uid: idFilter.value,
        aktif: statusFilter.value,
        cust_group: customerGroupFilter.value,
        cari_kod: cariKodFilter.value,
        adsoyad: nameFilter.value,
        username: usernameFilter.value,
        email: emailFilter.value,
        customer: customerFilter.value,
        updated: updatedFilter.value,
      })
      .then(response => {
        //userListTable.value = response.response.result
        userListTable.value = response.response.result.map(user => {
          // post_rules'u çözümle ve formatla
        
          if (user.pos_rules) {
            try {
              const rules = JSON.parse(user.pos_rules); // JSON'u çözümle
              // "bank:inst" formatında bir string oluştur
              user.pos_rules = rules.map(rule => `${rule.bank}:${rule.inst}`).join(', ');
             
            } catch (error) {
              console.error("JSON parse error:", error);
              user.post_rules = 'Hatalı format'; // Hata durumu
            }
          }
          return user;
        });

        totalUserListTable.value = Number(response.response.pagination.totalRec)
        loading.value = false

        // const { data, total, userTotal } = response.data.data
        // userListTable.value = data
        // totalUserListTable.value = total
        // userTotalLocal.value = userTotal

        // // remove loading state
        // loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  const fetchCustomerGroup = () => {
    store.dispatch('app-user/fetchCustomerGroup').then(response => {
      const data = response.response

      customerGroupOptions.value = data.map(key => ({ text: key.stext, value: key.group_code }))
    })
  }

  const fetchRole = () => {
    store.dispatch('app-user/fetchRole').then(response => {
      const data = response.detail

      roleOptions.value = data.map(key => ({ text: key.profile_name, value: key.profile_id, type: key.profile_type }))
    })
  }

  const fetchPricelist = () => {
    store.dispatch('app-user/fetchPricelist').then(response => {
      debugger
      const data = response.response

      pricelistOptions.value = data.map(key => ({ text: key.stext, value: key.group_code }))
    })
  }

  watch(
    [
      searchQuery,
      idFilter,
      customerGroupFilter,
      cariKodFilter,
      statusFilter,
      nameFilter,
      usernameFilter,
      emailFilter,
      customerFilter,
      updatedFilter,
      options,
    ],
    () => {
      loading.value = true
      selectedRows.value = []
      fetchUsers()
    },
  )

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'plasiyer') return 'warning'
    if (role === 'customer') return 'info'
    if (role === 'admin') return 'error'

    return 'primary'
  }

  const resolveUserStatusVariant = aktif => {
    if (aktif === '2') return 'warning'
    if (aktif === '1') return 'success'
    if (aktif === '3') return 'secondary'

    return 'primary'
  }

  const resolveUserTotalIcon = total => {
    if (total === 'Total Users') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Users') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Users') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Users') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    userListTable,
    tableColumns,
    searchQuery,
    idFilter,
    customerGroupFilter,
    cariKodFilter,
    statusFilter,
    nameFilter,
    usernameFilter,
    emailFilter,
    customerFilter,
    updatedFilter,
    totalUserListTable,
    statusOptions,
    customerGroupOptions,
    roleOptions,
    pricelistOptions,
    loading,
    options,
    userTotalLocal,
    selectedRows,
    fetchUsers,
    fetchPricelist,
    fetchCustomerGroup,
    fetchRole,
    resolveUserRoleVariant,
    resolveUserStatusVariant,
    resolveUserTotalIcon,
  }
}
